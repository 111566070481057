import React, { useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import SectionContainer from '../SectionContainer';
import { ButtonTabNavHorizontal } from '../../../../components';
import browse from './images/browse.png'
import connect from './images/connect.png'
import buy from './images/buy.png'
import wear from './images/wear.png'
import list from './images/list.png'
import connectSeller from './images/connectSeller.png'
import ship from './images/ship.png'
import earn from './images/earn.png'
import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const {
    sectionId,
    className,
    rootClassName,
    intl,
  } = props;

  const [selectedTab, setSelectedTab] = useState('seller');

  const buttonTabs = [
    { text: intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.seller' }), onClick: () => setSelectedTab('seller'), selected: selectedTab === 'seller' },
    { text: intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.buyer' }), onClick: () => setSelectedTab('buyer'), selected: selectedTab === 'buyer' },
  ];

  const sellerTabContent = (
    <div className={css.howItWorks}>
      <div className={css.block}>
        <div className={css.icon}><img src={list} alt={intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.list'})} /></div>
        <div className={css.description}>{intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.listDescription' })}</div>
      </div>
      <div className={css.block}>
        <div className={css.icon}><img src={connectSeller} alt={intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.connectSeller'})} /></div>
        <div className={css.description}>{intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.connectSellerDescription' })}</div>
      </div>
      <div className={css.block}>
        <div className={css.icon}><img src={ship} alt={intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.ship'})} /></div>
        <div className={css.description}>{intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.shipDescription' })}</div>
      </div>
      <div className={css.block}>
        <div className={css.icon}><img src={earn} alt={intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.earn'})} /></div>
        <div className={css.description}>{intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.earnDescription' })}</div>
      </div>
    </div>
  );

  const buyerTabContent = (
    <div className={css.howItWorks}>
      <div className={css.block}>
        <div className={css.icon}><img src={browse} alt={intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.browse'})} /></div>
        <div className={css.description}>{intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.browseDescription' })}</div>
      </div>
      <div className={css.block}>
        <div className={css.icon}><img src={connect} alt={intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.connect'})} /></div>
        <div className={css.description}>{intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.connectDescription' })}</div>
      </div>
      <div className={css.block}>
        <div className={css.icon}><img src={buy} alt={intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.buy'})} /></div>
        <div className={css.description}>{intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.buyDescription' })}</div>
      </div>
      <div className={css.block}>
        <div className={css.icon}><img src={wear} alt={intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.wear'})} /></div>
        <div className={css.description}>{intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.wearDescription' })}</div>
      </div>
    </div>
  );
 
  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
    >
      <div className={css.main}>
        <div className={css.header}>
          <div>{intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.header' })}</div>
          <div className={css.tabs}><ButtonTabNavHorizontal className={css.tabs} tabs={buttonTabs} skin="light" /></div>
        </div>
        {selectedTab === 'seller' ? (
          <div>{sellerTabContent}</div>
        ) : selectedTab === 'buyer' ? (
          <div>{buyerTabContent}</div>
        ) : null}
      </div>
    </SectionContainer>
  );
};

SectionHowItWorks.defaultProps = {
  className: null,
  rootClassName: null,
};

SectionHowItWorks.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
};

export default SectionHowItWorks;

import React, { useEffect } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import { ListingCard, IconArrowRight } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';

import SectionContainer from '../SectionContainer';
import css from './SectionListings.module.css';

const KEY_CODE_ARROW_LEFT = 37;
const KEY_CODE_ARROW_RIGHT = 39;

// The number of columns (numColumns) affects styling and responsive images
const COLUMN_CONFIG = [
  { css: css.oneColumn, responsiveImageSizes: '(max-width: 767px) 100vw, 1200px' },
  { css: css.twoColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 600px' },
  { css: css.threeColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 400px' },
  { css: css.fourColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 290px' },
];
const getIndex = numColumns => numColumns - 1;
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};

// Section component that's able to show blocks in a carousel
// the number blocks visible is defined by "numColumns" prop.
const SectionListings = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    numColumns,
    title,
    description,
    appearance,
    callToAction,
    options,
    intl,
    listings,
    currentUser,
    currencyRates,
  } = props;

  const sliderContainerId = `${props.sectionId}-listings-container`;
  const sliderId = `${props.sectionId}-listings-slider`;
  const numberOfBlocks = 4;
  const hasBlocks = true;
  const listingsData = listings[sectionId];
  const data = listingsData?.listings || [];
  const query = listingsData?.query || '';

  if (!data || !data?.length > 0) {
    return false;
  }

  useEffect(() => {
    const setCarouselWidth = () => {
      if (hasBlocks) {
        const windowWidth = window.innerWidth;
        const elem = window.document.getElementById(sliderContainerId);
        const scrollbarWidth = window.innerWidth - document.body.clientWidth;
        const elementWidth =
          elem.clientWidth >= windowWidth - scrollbarWidth ? windowWidth : elem.clientWidth;
        const carouselWidth = elementWidth - scrollbarWidth;

        elem.style.setProperty('--carouselWidth', `${carouselWidth}px`);
      }
    };
    setCarouselWidth();

    window.addEventListener('resize', setCarouselWidth);
    return () => window.removeEventListener('resize', setCarouselWidth);
  }, []);

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  const onSlideLeft = e => {
    var slider = window.document.getElementById(sliderId);
    const slideWidth = numColumns * slider?.firstChild?.clientWidth;
    slider.scrollLeft = slider.scrollLeft - slideWidth;
    // Fix for Safari
    e.target.focus();
  };

  const onSlideRight = e => {
    var slider = window.document.getElementById(sliderId);
    const slideWidth = numColumns * slider?.firstChild?.clientWidth;
    slider.scrollLeft = slider.scrollLeft + slideWidth;
    // Fix for Safari
    e.target.focus();
  };

  const onKeyDown = e => {
    if (e.keyCode === KEY_CODE_ARROW_LEFT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideLeft(e);
    } else if (e.keyCode === KEY_CODE_ARROW_RIGHT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideRight(e);
    }
  };

  const cardRenderSizes = () => {
    return [
      '(max-width: 549px) 100vw',
      '(max-width: 767px) 50vw',
      `(max-width: 1439px) 26vw`,
      `(max-width: 1920px) 18vw`,
      `14vw`,
    ].join(', ');
  };

  const listingsCarousal = listingsData => (
    <div className={css.carouselContainer} id={sliderContainerId}>
      <div
        className={classNames(css.carouselArrows, {
          [css.notEnoughBlocks]: numberOfBlocks <= numColumns,
        })}
      >
        <button className={css.carouselArrowPrev} onClick={onSlideLeft} onKeyDown={onKeyDown}>
          ‹
        </button>
        <button className={css.carouselArrowNext} onClick={onSlideRight} onKeyDown={onKeyDown}>
          ›
        </button>
      </div>
      <div className={getColumnCSS(numColumns)} id={sliderId}>
        {listingsData.map(l => (
          <ListingCard
            className={classNames(css.listingCard, css.block)}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes()}
            currentUser={currentUser}
            currencyRates={currencyRates}
          />
        ))}

        <a className={classNames(css.seeAll, css.listingCard, css.block)} href={query}>
          <FormattedMessage id="LandingPage.SectionListings.seeAll" />
          <IconArrowRight />
        </a>
      </div>
    </div>
  );

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      {hasBlocks ? (
        <div>
          <div>
            <div className={css.header}>
              <div>
              <a className={css.headerLink} href={query}>
                <FormattedMessage id={`LandingPage.SectionListings.${sectionId}.header`} />
              </a>
              </div>
            </div>
            <div>{listingsCarousal(data)}</div>
          </div>
        </div>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionListings.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  options: null,
};

SectionListings.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionListings;
